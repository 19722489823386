/* global google */

import {getLayer, getDataLayerUrls} from '@scripts/layers.js';

let dataLayersResponse;
let layerId = 'monthlyFlux';
let layer;

let month = 0;
let day = 14;

let overlays = [];
let showRoofOnly = false;

export const showDataLayer = async (
  reset = false,
  buildingInsights,
  geometryLibrary,
) => {
  if (reset) {
    dataLayersResponse = undefined;
    layer = undefined;
    showRoofOnly = ['annualFlux', 'monthlyFlux', 'hourlyShade'].includes(
      layerId,
    );
    window.gs_map.setMapTypeId(layerId === 'rgb' ? 'roadmap' : 'satellite');
    overlays.map((overlay) => overlay.setMap(null));
    month = 3;
    day = 14;
  }
  if (layerId === 'none') {
    return;
  }

  if (!layer) {
    const center = buildingInsights.center;
    const ne = buildingInsights.boundingBox.ne;
    const sw = buildingInsights.boundingBox.sw;
    const diameter = geometryLibrary.spherical.computeDistanceBetween(
      new google.maps.LatLng(ne.latitude, ne.longitude),
      new google.maps.LatLng(sw.latitude, sw.longitude),
    );
    const radius = Math.ceil(diameter / 2);
    try {
      dataLayersResponse = await getDataLayerUrls(
        center,
        radius,
        window.GS_GOOGLE_MAPS_API_KEY,
      );
    } catch (e) {
      console.error(e);
      return;
    }

    try {
      layer = await getLayer(
        layerId,
        dataLayersResponse,
        window.GS_GOOGLE_MAPS_API_KEY,
      );
    } catch (e) {
      console.error(e);
      return;
    }
  }

  const bounds = layer.bounds;
  overlays.map((overlay) => overlay.setMap(null));
  overlays = layer
    .render(showRoofOnly, month, day)
    .map((canvas) => new google.maps.GroundOverlay(canvas.toDataURL(), bounds));

  if (layer.id === 'monthlyFlux') {
    overlays.map((overlay) => overlay.setMap(window.gs_map));
  }
};
