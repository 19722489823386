import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

export const showHiddenElementorFormRadioLabels = (radios) => {
  radios.forEach((radio) => {
    const label = radio.querySelector('label');
    label.classList.remove('elementor-screen-only');
  });
};

export const setElementorFormSelectTextColor = (selects) => {
  selects.forEach((select) => {
    select.addEventListener('change', function () {
      if (this.selectedIndex === 0) {
        this.style.color = 'rgb(30 30 30 / 50%)';
      } else {
        this.style.color = 'rgb(30 30 30 / 100%)';
      }
    });
    const event = new Event('change');
    select.dispatchEvent(event);
  });
};

export const setElementorFieldGroupIcons = (fieldGroups) => {
  fieldGroups.forEach((group) => {
    const icon = group.getAttribute('data-field-icon');
    if (icon) {
      let fields = group.querySelectorAll('input, textarea, select');
      fields = Array.from(fields).filter((field) => {
        const type = field.type.toLowerCase();
        return type !== 'radio' && type !== 'checkbox';
      });

      fields.forEach((field) => {
        // Create iconElement
        let iconElement = document.createElement('div');
        iconElement.className = 'field-icon';
        iconElement.innerHTML = icon;

        // Create fieldWrapper
        let fieldWrapper = document.createElement('div');
        fieldWrapper.className = 'field-wrapper';

        // Create main wrapper
        let wrapper = document.createElement('div');
        wrapper.className = 'field-with-icon';

        // Insert the main wrapper in the DOM before the field
        field.parentNode.insertBefore(wrapper, field);

        // Append iconElement and fieldWrapper to the main wrapper
        wrapper.appendChild(iconElement);
        wrapper.appendChild(fieldWrapper);

        // Move the field inside the fieldWrapper
        fieldWrapper.appendChild(field);
      });
    }
  });
};

export const enableFilePondWithImagePreviews = (fileInputs) => {
  FilePond.registerPlugin(FilePondPluginImagePreview);
  fileInputs.forEach((input) => {
    let inputType = input
      .getAttribute('name')
      .replace('form_fields', '')
      .replace('[', '')
      .replace(']', '')
      .replace('[]', '')
      .replace('_', ' ');
    FilePond.create(input, {
      credits: false,
      storeAsFile: true,
      labelIdle: '+<br />Click here<br />to upload your ' + inputType,
      iconRemove:
        '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0833 5.25L10.6657 10.679C10.5722 11.8946 9.55851 12.8333 8.33926 12.8333H5.66074C4.44149 12.8333 3.42779 11.8946 3.33428 10.679L2.91667 5.25M12.25 4.08333C10.7345 3.34482 8.93296 2.91667 7 2.91667C5.06704 2.91667 3.26545 3.34482 1.75 4.08333M5.83333 2.91667V2.33333C5.83333 1.689 6.35567 1.16667 7 1.16667C7.64433 1.16667 8.16667 1.689 8.16667 2.33333V2.91667M5.83333 6.41667V9.91667M8.16667 6.41667V9.91667" stroke="#FF492C" stroke-width="1.5" stroke-linecap="round"/></svg>',
    });
  });
};
