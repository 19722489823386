import Choices from 'choices.js';

export const enableChoices = (selectors) => {
  selectors.forEach((selector) => {
    let placeholderLabel = '';
    const labelElement =
      selector.parentNode.parentNode.parentNode.querySelector('label');

    if (labelElement) {
      const labelText = labelElement.textContent || labelElement.innerText;
      placeholderLabel = labelText.replace(/\s+/g, ' ').trim();
    }

    new Choices(selector, {
      removeItemButton: true,
      placeholderValue: placeholderLabel,
      searchEnabled: true,
      allowHTML: false,
      searchPlaceholderValue: placeholderLabel,
      itemSelectText: 'Click to select',
    });
  });
};
