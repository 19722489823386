/* global google */

import {Loader} from '@googlemaps/js-api-loader';
import {panelsPalette} from '@scripts/colours.js';
import {
  createPalette,
  findMiddleNumber,
  normalize,
  rgbToColor,
} from '@scripts/utilities.js';
import {showDataLayer} from '@scripts/dataLayers.js';

let requestSent = false;
let geometryLibrary = {};

export const solarPanelConfig = {
  panelsCount: 0,
  yearlyEnergyDcKwh: 0,
  roofSegmentSummaries: [],
};

// const roofSegmentSummary = {
//   pitchDegrees: 0,
//   azimuthDegrees: 0,
//   panelsCount: 0,
//   yearlyEnergyDcKwh: 0,
//   segmentIndex: 0,
// };

let buildingInsights = {
  solarPotential: {
    solarPanels: [],
    panelWidthMeters: 0,
    panelHeightMeters: 0,
    roofSegmentStats: [],
    solarPanelConfigs: [],
    maxArrayPanelsCount: 0,
    maxSunshineHoursPerYear: 0,
    maxArrayAreaMeters2: 0,
  },
};

const zoom = 19;

const findClosestBuilding = async (location, apiKey) => {
  const args = {
    'location.latitude':
      typeof location.lat === 'function'
        ? location.lat().toFixed(5)
        : location.lat.toFixed(5),
    'location.longitude':
      typeof location.lng === 'function'
        ? location.lng().toFixed(5)
        : location.lng.toFixed(5),
  };
  const params = new URLSearchParams({...args, key: apiKey});
  return fetch(
    `https://solar.googleapis.com/v1/buildingInsights:findClosest?${params}`,
  ).then(async (response) => {
    const content = await response.json();
    if (response.status !== 200) {
      throw content;
    }
    return content;
  });
};

const showSolarPotential = async (location) => {
  if (requestSent) {
    return;
  }
  let solarPanels = [];
  let showPanels = true;
  let panelConfig = solarPanelConfig;

  solarPanels.map((panel) => panel.setMap(null));
  solarPanels = [];

  requestSent = true;
  try {
    buildingInsights = await findClosestBuilding(
      location,
      window.GS_GOOGLE_MAPS_API_KEY,
    );
  } catch (e) {
    console.error(e);
    return;
  } finally {
    requestSent = false;
  }

  // // Create the solar panels on the map.
  const solarPotential = buildingInsights.solarPotential;
  const palette = createPalette(panelsPalette, 256).map(rgbToColor);
  const minEnergy = solarPotential.solarPanels.slice(-1)[0].yearlyEnergyDcKwh;
  const maxEnergy = solarPotential.solarPanels[0].yearlyEnergyDcKwh;

  solarPanels = solarPotential.solarPanels.map((panel) => {
    const [w, h] = [
      solarPotential.panelWidthMeters / 2,
      solarPotential.panelHeightMeters / 2,
    ];
    const points = [
      {x: +w, y: +h}, // top right
      {x: +w, y: -h}, // bottom right
      {x: -w, y: -h}, // bottom left
      {x: -w, y: +h}, // top left
      {x: +w, y: +h}, //  top right
    ];
    const orientation = panel.orientation === 'PORTRAIT' ? 90 : 0;
    const azimuth =
      solarPotential.roofSegmentStats[panel.segmentIndex].azimuthDegrees;
    const colorIndex = Math.round(
      normalize(panel.yearlyEnergyDcKwh, maxEnergy, minEnergy) * 255,
    );
    return new google.maps.Polygon({
      paths: points.map(({x, y}) =>
        geometryLibrary.spherical.computeOffset(
          {lat: panel.center.latitude, lng: panel.center.longitude},
          Math.sqrt(x * x + y * y),
          Math.atan2(y, x) * (180 / Math.PI) + orientation + azimuth,
        ),
      ),
      strokeColor: '#B0BEC5',
      strokeOpacity: 0.9,
      strokeWeight: 1,
      fillColor: palette[colorIndex],
      fillOpacity: 0.9,
    });
  });

  // Determine maximum panel count for the roof
  let maxPanelCount = buildingInsights.solarPotential.maxArrayPanelsCount;
  let midPanelCount = findMiddleNumber(maxPanelCount);
  if (buildingInsights) {
    panelConfig =
      buildingInsights.solarPotential.solarPanelConfigs[midPanelCount];
  }

  solarPanels.map((panel, i) => {
    panel.setMap(
      showPanels && panelConfig && i < panelConfig.panelsCount
        ? window.gs_map
        : null,
    );
  });
};

const showResultsByPlace = async (place, searchAddress) => {
  const resultsWrapper = document.querySelector('.results-wrapper');
  if (resultsWrapper) {
    resultsWrapper.classList.remove('visible');
  }
  const calculatingOverlay = document.querySelector(
    '.gs-solar-calculator-overlay',
  );
  if (calculatingOverlay) {
    calculatingOverlay.classList.add('visible');
  }

  if (!place.geometry || !place.geometry.location) {
    searchAddress.value = '';
    return;
  }

  let location = place.geometry.location;

  if (place.geometry.viewport) {
    window.gs_map.setCenter(location);
    window.gs_map.setZoom(zoom);
  } else {
    window.gs_map.setCenter(location);
    window.gs_map.setZoom(zoom);
  }

  if (place.name) {
    searchAddress.value = place.name;
  } else if (place.formatted_address) {
    searchAddress.value = place.formatted_address;
  }

  await showSolarPotential(location);
  await showDataLayer(true, buildingInsights, geometryLibrary);

  window.currentMarker = new google.maps.Marker({
    position: location,
    map: window.gs_map,
    title: place.name || 'Selected Location', // Optional: add a title to the marker
  });

  const availableSunlightHours = document.querySelector(
    '.sunlight .available-hours',
  );
  const availableSquareFeet = document.querySelector(
    '.available-space .available-feet',
  );

  const savingsAmount = document.querySelector('.savings-amount .total');
  savingsAmount.textContent = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(savingsAmount.textContent));

  availableSunlightHours.textContent =
    buildingInsights.solarPotential.maxSunshineHoursPerYear.toFixed(2);

  const squareMeters = buildingInsights.solarPotential.maxArrayAreaMeters2;
  const squareFeet = squareMeters * 10.764;
  availableSquareFeet.textContent = squareFeet.toFixed(2);

  if (calculatingOverlay) {
    calculatingOverlay.classList.remove('visible');
  }

  if (resultsWrapper) {
    resultsWrapper.classList.add('visible');
  }

  localStorage.removeItem('gs_solar_savings_place');
};

export const enableSolarSavingsCalculator = (mapsPlaceholder) => {
  const googleMapsApiKey = window.GS_GOOGLE_MAPS_API_KEY;
  let mapsLibrary;
  let placesLibrary;
  let location;

  const searchAddress = document.querySelector('#address-search');

  if (mapsPlaceholder) {
    window.mapElement = document.querySelector('#go-sunward-map-placeholder');
    window.autocomplete = null;

    if (window.currentMarker) {
      window.currentMarker.setMap(null);
    }

    document.addEventListener('DOMContentLoaded', async () => {
      const loader = new Loader({apiKey: googleMapsApiKey});
      const libraries = {
        geometry: loader.importLibrary('geometry'),
        maps: loader.importLibrary('maps'),
        places: loader.importLibrary('places'),
      };
      placesLibrary = await libraries.places;

      // We are showing the map, so place the map, and data
      if (window.mapElement) {
        let defaultPlace = {
          name: 'San Franciso, California',
          address: '3401 Hillview Ave, Palo Alto, California 95054, US',
        };

        const storedLocation = JSON.parse(
          localStorage.getItem('gs_solar_savings_place'),
        );
        if (storedLocation) {
          defaultPlace = storedLocation;
        }

        geometryLibrary = await libraries.geometry;
        mapsLibrary = await libraries.maps;

        // Get the address information for the default location.
        const geocoder = new google.maps.Geocoder();
        let geocoderResponse = await geocoder.geocode({
          address: defaultPlace.address ?? defaultPlace.formatted_address,
        });
        let geocoderResult = geocoderResponse.results[0];

        // Initialize the map at the desired location.
        location = geocoderResult.geometry.location;
        window.gs_map = new mapsLibrary.Map(window.mapElement, {
          center: location,
          zoom: zoom,
          tilt: 0,
          mapTypeId: 'satellite',
          mapTypeControl: false,
          fullscreenControl: false,
          rotateControl: false,
          streetViewControl: false,
          zoomControl: false,
        });

        if (storedLocation) {
          await showResultsByPlace(defaultPlace, searchAddress);
        }
      }

      window.autocomplete = new placesLibrary.Autocomplete(searchAddress, {
        fields: ['formatted_address', 'geometry', 'name'],
      });

      window.autocomplete.addListener('place_changed', async () => {
        if (!window.mapElement) {
          // We are searching on the calculator landing page, and there is no map, so store
          // and redirect
          const place = window.autocomplete.getPlace();
          if (!place.geometry || !place.geometry.location) {
            searchAddress.value = '';
            return;
          }

          location = place.geometry.location;
          if (place.name) {
            searchAddress.value = place.name;
          } else if (place.formatted_address) {
            searchAddress.value = place.formatted_address;
          }

          const redirectUrl = document.querySelector(
            'input[name="redirect-to-results"]',
          );

          if (redirectUrl.value) {
            localStorage.setItem(
              'gs_solar_savings_place',
              JSON.stringify(place),
            );
            window.location = redirectUrl.value;
          } else {
            alert(' There is no redirect URL set.');
          }
        } else {
          await showResultsByPlace(
            window.autocomplete.getPlace(),
            searchAddress,
          );
        }
      });
    });
  }
};
