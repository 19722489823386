import domReady from '@roots/sage/client/dom-ready';
import {enableSolarSavingsCalculator} from '@scripts/solar.js';
import {
  enableFilePondWithImagePreviews,
  setElementorFieldGroupIcons,
  setElementorFormSelectTextColor,
  showHiddenElementorFormRadioLabels,
} from '@scripts/forms.js';
import {
  intializeVendorSelect2,
  handleVendorContactClick,
  decryptEncryptedString,
  handleDecodeBeforeClickthrough,
  handleModalOpen,
  handleModalClose,
  handleVendorLogin,
  handleVendorEdit,
} from '@scripts/vendors.js';

import {handleMobileMenu} from '@scripts/menus.js';
import {enableChoices} from '@scripts/choices.js';

domReady(async () => {
  // Google Maps - Solar API
  enableSolarSavingsCalculator(
    document.querySelector('.gs-solar-maps-wrapper'),
  );

  // Filepond image Previews
  enableFilePondWithImagePreviews(
    document.querySelectorAll('input[type="file"]'),
  );

  // Handle form field icons
  setElementorFieldGroupIcons(
    document.querySelectorAll('.elementor-field-group'),
  );

  // Handle select option colours
  setElementorFormSelectTextColor(
    document.querySelectorAll('select.elementor-field-textual'),
  );

  // Remove hidden labels on radio form elements
  showHiddenElementorFormRadioLabels(
    document.querySelectorAll('.elementor-field-type-radio'),
  );

  // Vendor Select2
  intializeVendorSelect2(document.querySelectorAll('.select2'));

  // Handle Vendor Contact Information
  handleVendorContactClick(
    document.querySelector('button.vendor-contact-information'),
  );

  decryptEncryptedString(document.querySelectorAll('.encoded'));

  handleDecodeBeforeClickthrough(
    document.querySelectorAll('a.decode-before-click'),
  );

  handleModalOpen(document.querySelectorAll('.show-modal'));
  handleModalClose(document.querySelector('.close-modal'));

  // Menu;
  handleMobileMenu(document.getElementById('menu-toggle'));

  // Vendor Login
  handleVendorLogin(document.querySelector('#vendor-login-button a'));

  handleVendorEdit(document.querySelectorAll('.vendor-edit'));

  enableChoices(
    document.querySelectorAll('.elementor-field-type-gs_select2 select'),
  );
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
