import $ from 'jquery';
import 'select2';

export const intializeVendorSelect2 = (selector) => {
  $(selector).select2();
};

export const handleVendorContactClick = (selector) => {
  if (selector) {
    selector.addEventListener('click', (event) => {
      event.preventDefault();

      const vendorContactInformation = document.querySelector(
        '.vendor-contact-information-list',
      );
      const caratDown = document.querySelector('.carat-down');
      const caratUp = document.querySelector('.carat-up');

      const isHidden = event.currentTarget.classList.contains(
        'vendor-contact-information__hidden',
      );
      event.currentTarget.classList.toggle(
        'vendor-contact-information__hidden',
        !isHidden,
      );
      event.currentTarget.classList.toggle(
        'vendor-contact-information__visible',
        isHidden,
      );

      caratDown.classList.toggle('hidden', !isHidden);
      caratDown.classList.toggle('visible', !isHidden);

      caratUp.classList.toggle('visible', isHidden);
      caratUp.classList.toggle('hidden', !isHidden);

      vendorContactInformation.classList.toggle('hidden', !isHidden);
      vendorContactInformation.classList.toggle('visible', isHidden);
    });
  }
};

export const handleDecodeBeforeClickthrough = (selector) => {
  if (selector) {
    selector.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        const href = event.currentTarget.getAttribute('href');
        let url =
          '/wp-json/go_sunward/v1/redirect?vid=' +
          link.getAttribute('data-vendor-id') +
          '&t=' +
          link.getAttribute('data-clickthrough-type') +
          '&time=' +
          Math.floor(Date.now() / 1000);

        // Make sure we split the URLs effectively to handle mail/tel
        if (href.includes('tel:') || href.includes('mailto:')) {
          const split = href.split(':');
          url += '&u=' + encodeURIComponent(xor_decrypt(split[1], 'gs2024'));
          window.location.href = url;
        } else {
          url += '&u=' + encodeURIComponent(xor_decrypt(href, 'gs2024'));
          // Ensure we open in a new tab
          window.open(url, '_blank');
        }
      });
    });
  }
};

export const handleModalOpen = (selector) => {
  if (selector) {
    selector.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        const modalBackground = document.querySelector('.modal-background');
        const modalContent = document.querySelector('.modal-content');
        modalBackground.classList.remove('hidden');
        modalContent.classList.remove('hidden');
      });
    });
  }
};

export const handleModalClose = (selector) => {
  if (selector) {
    selector.addEventListener('click', (event) => {
      event.preventDefault();
      const modalBackground = document.querySelector('.modal-background');
      const modalContent = document.querySelector('.modal-content');
      modalBackground.classList.add('hidden');
      modalContent.classList.add('hidden');
    });
  }
};

export const decryptEncryptedString = (selector) => {
  if (selector) {
    selector.forEach((element) => {
      if (element.classList.contains('has-phone-number')) {
        element.textContent = '+' + xor_decrypt(element.textContent, 'gs2024');
      } else {
        element.textContent = xor_decrypt(element.textContent, 'gs2024');
      }
    });
  }
};

export const handleVendorLogin = (selector) => {
  if (selector) {
    selector.addEventListener('click', (event) => {
      event.preventDefault();

      const username = document.getElementById('form-field-email').value;
      const password = document.getElementById('form-field-password').value;

      const formData = new URLSearchParams();
      formData.append('action', 'vendor_login_action');
      formData.append('vendor_email', username);
      formData.append('vendor_password', password);

      fetch('/wp-admin/admin-ajax.php', {
        method: 'POST',
        body: formData,
        credentials: 'same-origin',
      })
        .then((response) => {
          if (response.status) {
            return response.text();
          }
          throw new Error('There was a network error. Please try again');
        })
        .then((text) => {
          if (text) {
            const serverResponse = JSON.parse(text);
            if (serverResponse.success) {
              // We have a successful login
              window.location.href = '/vendors/edit-vendor-profile/';
            } else {
              alert(serverResponse.error);
            }
          }
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    });
  }
};

export const handleVendorEdit = (selector) => {
  if (selector) {
    selector.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();

        const selected = event.currentTarget;
        const vendorId = selected.getAttribute('data-vendor-id');
        const isSelectedDisabled = selected.classList.contains('disabled');
        if (isSelectedDisabled) {
          return;
        }

        let isEditing = false;
        if (selected.classList.contains('editing')) {
          selected.classList.remove('editing');
          isEditing = false;
        } else {
          selected.classList.add('editing');
          isEditing = true;
        }

        selector.forEach((el) => {
          if (el !== selected) {
            if (!el.classList.contains('disabled')) {
              el.classList.add('opacity-20', 'disabled');
              el.classList.remove(
                'hover:cursor-pointer',
                'hover:bg-primary/60',
              );
            } else {
              el.classList.remove('opacity-20', 'disabled');
              el.classList.add('hover:cursor-pointer', 'hover:bg-primary/60');
            }
          }
        });

        const vendorForm = document.querySelector(
          '.vendor-editor-form-wrapper',
        );

        if (vendorId) {
          vendorForm.classList.toggle('hidden');
        }

        if (isEditing) {
          const editorForms = vendorForm.querySelectorAll('form');
          editorForms.forEach((form) => {
            if (form.getAttribute('id') !== `vendor-form-${vendorId}`) {
              form.classList.add('hidden');
            } else {
              form.classList.remove('hidden');
            }
          });

          // const formDataRaw = selected.querySelectorAll(
          //   `input[name^="vendor[${vendorId}]"]`,
          // );
          //
          // const formData = {};
          // formDataRaw.forEach((input) => {
          //   const key = input.name.split('[').pop().split(']')[0];
          //   formData[key] = input.value;
          //   const formField = vendorForm.querySelector(
          //     `input[name^="editor_${key}"], textarea[name^="editor_${key}"]`,
          //   );
          //   if (formField) {
          //     formField.value = input.value;
          //   } else {
          //     console.log(`Field not found for key: editor_${key}`);
          //   }
          // });
          //
          // console.log(formData);
        }
      });
    });
  }
};

const xor_decrypt = (input, key) => {
  let output = '';
  input = hex_to_ascii(input);
  for (let i = 0; i < input.length; i++) {
    output += String.fromCharCode(
      input.charCodeAt(i) ^ key.charCodeAt(i % key.length),
    );
  }
  return output;
};

const hex_to_ascii = (str) => {
  let hexString = str.toString();
  let strOut = '';
  for (let i = 0; i < hexString.length; i += 2) {
    strOut += String.fromCharCode(parseInt(hexString.substr(i, 2), 16));
  }
  return strOut;
};
